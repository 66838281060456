import React from "react";

// Components
import SecondaryLanding from "../layouts/secondary-landing";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import SEO from "../components/seo/seo";
import NavButtons from "../components/navigation/nav-buttons";
import Icon from "../components/custom-widgets/icon";
import MktoForm from "../components/mkto-form/mkto-form";

import {
  CCPANoticeData,
  CCPARequestData,
  CookieControlData,
  CookiePolicyData,
  PrivacyPolicyData,
  ProtectingYourPrivacyData,
  usePrivacyCenterScrollToTop
} from "../components/privacy-center";

// Styles
import styles from "../pages/personal-banking/interest-rates.module.scss";

const PrivacyCenter = () => {
  const title = "Online Privacy Policy";
  const description =
    "At WaFd Bank, trust has always been the foundation of our relationship with customers; we respect your privacy and safeguard your online privacy.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/privacy-center"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 2,
      active: true,
      title: "Privacy Center"
    }
  ];

  const navButtonsData = {
    title: "Privacy Center",
    subTitle: "Your privacy is important to us and we appreciate your trust.",
    tabsData: [
      {
        id: "privacy-policy",
        title: "Privacy Policy",
        component: <PrivacyPolicyData />,
        description: "We are open and honest about our privacy practices",
        iconSrc: "../../images/icons/privacy-policy-icon.svg",
        contentId: "privacy-policy"
      },
      {
        id: "ccpa-notice",
        title: "CCPA Notice",
        component: <CCPANoticeData />,
        description: "The California Consumer Privacy Act (CCPA) Notice",
        iconSrc: "../../images/icons/ccpa-notice-icon.svg",
        contentId: "ccpa-notice"
      },
      {
        id: "ccpa-request",
        title: "CCPA Request",
        component: <CCPARequestData />,
        description: "You have the right to know, change or delete your information",
        iconSrc: "../../images/icons/ccpa-request-icon.svg",
        contentId: "ccpa-request"
      },
      {
        id: "protecting-your-privacy",
        title: "Protecting Your Privacy",
        component: <ProtectingYourPrivacyData />,
        description: "How we collect, share and protect your information",
        iconSrc: "../../images/icons/protecting-your-privacy-icon.svg",
        contentId: "protecting-your-privacy"
      },
      {
        id: "cookie-control",
        title: "Cookie Control",
        component: <CookieControlData />,
        description: "You're in control and we give you clear simple choices",
        iconSrc: "../../images/icons/privacy-control-icon.svg",
        contentId: "cookie-control"
      },
      {
        id: "cookie-policy",
        title: "Cookie Policy",
        component: <CookiePolicyData />,
        description: "We use cookies to provide a more personalized experience",
        iconSrc: "../../images/icons/cookie-policy-icon.svg",
        contentId: "cookie-policy"
      }
    ]
  };

  const { scrollToTop, backToTopRef } = usePrivacyCenterScrollToTop();

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb containerClass="mb-3" data={breadcrumbData} />
      <MktoForm />
      <NavButtons {...navButtonsData} />
      <div
        ref={backToTopRef}
        id="back-to-top-btn"
        role="button"
        onClick={scrollToTop}
        onKeyDown={scrollToTop}
        className={`${styles.backToTop} mb-0 bg-primary text-white py-2 px-3 border-radius-top-left-6`}
      >
        <Icon name="arrow-to-top" class="fa-lg" />
      </div>
    </SecondaryLanding>
  );
};
export default PrivacyCenter;
