import React from "react";
import { Link } from "gatsby";
import Icon from "../custom-widgets/icon";

const CCPANoticeData = () => {
  return (
    <>
      <h1 id="ccpa-notice-header">CALIFORNIA CONSUMER PRIVACY ACT NOTICE</h1>
      <p>
        <a
          href="/documents/privacy/ccpa-notice.pdf"
          className="text-decoration-none"
          id="ccpa-notice-pdf"
          target="_blank"
        >
          <Icon name="file-pdf" class="mr-1" />
          Printer friendly version of CCPA Notice
        </a>
      </p>
      <h3 id="ccpa-notice-subheading-1">APPLICABILITY</h3>
      <p id="ccpa-notice-p1">
        Your privacy is important to us. This California Consumer Privacy Act Notice (this "CCPA Notice") supplements
        the information contained in our{" "}
        <Link to="/privacy-center#privacy-policy" id="privacy-policy-link-1">
          www.wafdbank.com/privacy-center#privacy-policy
        </Link>{" "}
        and Consumer Privacy Policy. It also explains how Washington Federal Bank ("we", "us", or "WaFd Bank") collects,
        uses, and discloses the personal information of California residents pursuant to the California Consumer Privacy
        Act of 2018, as amended by the California Privacy Rights Act of 2020 (collectively, the "CCPA"). This CCPA
        Notice is provided pursuant to the CCPA.
      </p>
      <h3 id="ccpa-notice-subheading-2">BACKGROUND</h3>
      <p id="ccpa-notice-p2">
        Under the CCPA, "Personal Information" is information that identifies, relates to, or could reasonably be linked
        directly or indirectly with a particular California resident and includes certain categories of Personal
        Information discussed below that constitute "Sensitive Personal Information". The specific Personal Information
        that we collect, use, and disclose relating to a California resident covered by the CCPA will vary based on our
        relationship or interaction with that individual.
      </p>
      <p id="ccpa-notice-p3">
        It is important to note that Personal Information does not include: (i) publicly available information from
        government records; (ii) deidentified or aggregated consumer information; or (iii) information excluded from the
        CCPA's scope, including personal information covered by certain sector-specific privacy laws, including the Fair
        Credit Reporting Act (the "FCRA"), the Gramm-Leach-Bliley Act (the "GLBA") or California Financial Information
        Privacy Act (the "FIPA"), and the Driver's Privacy Protection Act of 1994. For example, information that we
        collect about California residents who apply for or obtain our financial products and services for personal,
        family, or household purposes fall under the scope of the GLBA, rather than the CCPA. For more information about
        how we collect, disclose, and secure information relating to these consumers, please refer to our{" "}
        <Link to="/privacy-center#privacy-policy" id="privacy-policy-link-2">
          www.wafdbank.com/privacy-center#privacy-policy
        </Link>
        .
      </p>
      <p id="ccpa-notice-p4">
        Keeping Personal Information secure is one of our most important priorities. Consistent with our obligations
        under applicable laws and regulations, we maintain physical, technical, electronic, procedural, and
        organizational safeguards and security measures that are designed to protect personal data against accidental,
        unlawful, or unauthorized destruction, loss, alteration, disclosure, or access, whether it is processed by us or
        elsewhere.
      </p>
      <h3 id="ccpa-notice-subheading-3">PERSONAL INFORMATION WE COLLECT</h3>
      <p id="ccpa-notice-p5">
        <strong>Personal Information:</strong> In the past 12 months, we have collected the following categories of
        Personal Information:
      </p>
      <table className="table table-bordered table-responsive" id="ccpa-notice-t1">
        <thead className="text-center" id="ccpa-notice-t1-h">
          <tr>
            <th id="ccpa-notice-t1-h1">Category</th>
            <th id="ccpa-notice-t1-h2">Examples</th>
            <th id="ccpa-notice-t1-h3">Collected</th>
          </tr>
        </thead>
        <tbody id="ccpa-notice-t1-b">
          <tr id="ccpa-notice-t1-tr1">
            <td id="ccpa-notice-t1-tr1-td1">A. Identifiers.</td>
            <td id="ccpa-notice-t1-tr1-td2">
              A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol
              address, email address, account name, Social Security number, driver's license number, passport number, or
              other similar identifiers.
            </td>
            <td id="ccpa-notice-t1-tr1-td3">YES</td>
          </tr>
          <tr id="ccpa-notice-t1-tr2">
            <td id="ccpa-notice-t1-tr2-td1">
              B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code
              &sect; 1798.80(e)).
            </td>
            <td id="ccpa-notice-t1-tr2-td2">
              <p>
                A name, signature, Social Security number, physical characteristics or description, address, telephone
                number, passport number, driver's license or state identification card number, insurance policy number,
                education, employment, employment history, bank account number, credit card number, debit card number,
                or any other financial information, medical information, or health insurance information.
              </p>
              <p>Some Personal Information included in this category may overlap with other categories.</p>
            </td>
            <td id="ccpa-notice-t1-tr2-td3">YES</td>
          </tr>
          <tr id="ccpa-notice-t1-tr3">
            <td id="ccpa-notice-t1-tr3-td1">
              C. Protected classification characteristics under California or federal law.
            </td>
            <td id="ccpa-notice-t1-tr3-td2">
              Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital
              status, medical condition, physical or mental disability, sex (including gender, gender identity, gender
              expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or
              military status, genetic information (including familial genetic information).
            </td>
            <td id="ccpa-notice-t1-tr3-td3">YES</td>
          </tr>
          <tr id="ccpa-notice-t1-tr4">
            <td id="ccpa-notice-t1-tr4-td1">D. Commercial information.</td>
            <td id="ccpa-notice-t1-tr4-td2">
              Records of personal property, products or services purchased, obtained, or considered, or other purchasing
              or consuming histories or tendencies.
            </td>
            <td id="ccpa-notice-t1-tr4-td3">YES</td>
          </tr>
          <tr id="ccpa-notice-t1-tr5">
            <td id="ccpa-notice-t1-tr5-td1">E. Biometric information.</td>
            <td id="ccpa-notice-t1-tr5-td2">
              Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a
              template or other identifier or identifying information, such as, fingerprints, faceprints, and
              voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or
              exercise data.
            </td>
            <td id="ccpa-notice-t1-tr5-td3">YES</td>
          </tr>
          <tr id="ccpa-notice-t1-tr6">
            <td id="ccpa-notice-t1-tr6-td1">F. Internet or other similar network activity.</td>
            <td id="ccpa-notice-t1-tr6-td2">
              Browsing history, search history, information on a consumer's interaction with a website, application, or
              advertisement.
            </td>
            <td id="ccpa-notice-t1-tr6-td3">YES</td>
          </tr>
          <tr id="ccpa-notice-t1-tr7">
            <td id="ccpa-notice-t1-tr7-td1">G. Geolocation data.</td>
            <td id="ccpa-notice-t1-tr7-td2">Physical location or movements.</td>
            <td id="ccpa-notice-t1-tr7-td3">YES</td>
          </tr>
          <tr id="ccpa-notice-t1-tr8">
            <td id="ccpa-notice-t1-tr8-td1">H. Sensory data.</td>
            <td id="ccpa-notice-t1-tr8-td2">Audio, electronic, visual, thermal, olfactory, or similar information.</td>
            <td id="ccpa-notice-t1-tr8-td3">YES</td>
          </tr>
          <tr id="ccpa-notice-t1-tr9">
            <td id="ccpa-notice-t1-tr9-td1">I. Professional or employment-related information.</td>
            <td id="ccpa-notice-t1-tr9-td2">Current or past job history or performance evaluations.</td>
            <td id="ccpa-notice-t1-tr9-td3">YES</td>
          </tr>
          <tr id="ccpa-notice-t1-tr10">
            <td id="ccpa-notice-t1-tr10-td1">
              J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section
              1232g, 34 C.F.R. Part 99)).
            </td>
            <td id="ccpa-notice-t1-tr10-td2">
              Education records directly related to a student maintained by an educational institution or party acting
              on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes,
              student financial information, or student disciplinary records.
            </td>
            <td id="ccpa-notice-t1-tr10-td3">YES</td>
          </tr>
          <tr id="ccpa-notice-t1-tr11">
            <td id="ccpa-notice-t1-tr11-td1">K. Inferences drawn from other personal information.</td>
            <td id="ccpa-notice-t1-tr11-td2">
              Profile reflecting a person's preferences, characteristics, psychological trends, predispositions,
              behavior, attitudes, intelligence, abilities, and aptitudes.
            </td>
            <td id="ccpa-notice-t1-tr11-td3">YES</td>
          </tr>
        </tbody>
      </table>
      <p id="ccpa-notice-p6">
        <strong>Sensitive Personal Information:</strong> Personal Information that reveals any of the information listed
        in the categories in the table below constitutes "Sensitive Personal Information". In the past 12 months, we
        have collected the following categories of Sensitive Personal Information:
      </p>
      <table className="table table-bordered table-responsive" id="ccpa-notice-t2">
        <thead className="text-center" id="ccpa-notice-t2-h">
          <tr>
            <th id="ccpa-notice-t2-h1">Category</th>
            <th id="ccpa-notice-t2-h2">Collected</th>
          </tr>
        </thead>
        <tbody id="ccpa-notice-t2-b">
          <tr id="ccpa-notice-t2-tr1">
            <td id="ccpa-notice-t2-tr1-td1">
              A. Social security, driver's license, state identification card, or passport number.
            </td>
            <td id="ccpa-notice-t2-tr1-td2">YES</td>
          </tr>
          <tr id="ccpa-notice-t2-tr2">
            <td id="ccpa-notice-t2-tr2-td1">
              B. Account log-in, financial account, debit card, or credit card number in combination with any required
              security or access code, password, or credentials allowing access to an account.
            </td>
            <td id="ccpa-notice-t2-tr2-td2">YES</td>
          </tr>
          <tr id="ccpa-notice-t2-tr3">
            <td id="ccpa-notice-t2-tr3-td1">C. Precise geolocation.</td>
            <td id="ccpa-notice-t2-tr3-td2">YES</td>
          </tr>
          <tr id="ccpa-notice-t2-tr4">
            <td id="ccpa-notice-t2-tr4-td1">
              D. Racial or ethnic origin, religious or philosophical beliefs, or union membership.
            </td>
            <td id="ccpa-notice-t2-tr4-td2">YES</td>
          </tr>
          <tr id="ccpa-notice-t2-tr5">
            <td id="ccpa-notice-t2-tr5-td1">
              E. Contents of a consumer's mail, email, and text messages unless the business is the intended recipient
              of the communication.
            </td>
            <td id="ccpa-notice-t2-tr5-td2">NO</td>
          </tr>
          <tr id="ccpa-notice-t2-tr6">
            <td id="ccpa-notice-t2-tr6-td1">F. Genetic data.</td>
            <td id="ccpa-notice-t2-tr6-td2">NO</td>
          </tr>
          <tr id="ccpa-notice-t2-tr7">
            <td id="ccpa-notice-t2-tr7-td1">
              G. The processing of biometric information for the purpose of uniquely identifying a consumer.
            </td>
            <td id="ccpa-notice-t2-tr7-td2">YES</td>
          </tr>
          <tr id="ccpa-notice-t2-tr8">
            <td id="ccpa-notice-t2-tr8-td1">
              H. Personal Information collected and analyzed concerning a consumer's health.
            </td>
            <td id="ccpa-notice-t2-tr8-td2">NO</td>
          </tr>
          <tr id="ccpa-notice-t2-tr9">
            <td id="ccpa-notice-t2-tr9-td1">
              I. Personal Information collected and analyzed concerning a consumer's sex life or sexual orientation.
            </td>
            <td id="ccpa-notice-t2-tr9-td2">NO</td>
          </tr>
        </tbody>
      </table>
      <h3 id="ccpa-notice-subheading-4">PERSONAL INFORMATION SOURCES</h3>
      <p id="ccpa-notice-p7">
        The categories of sources from whom we collected the Personal Information and Sensitive Personal Information
        listed above include the following:
      </p>
      <ul id="ccpa-notice-ul-1">
        <li id="ccpa-notice-ul-1-li-1">
          Directly from you or an authorized agent or family member, through direct interactions and forms
        </li>
        <li id="ccpa-notice-ul-1-li-2">
          Service Providers and other third parties (e.g., credit bureaus or mortgage brokers)
        </li>
        <li id="ccpa-notice-ul-1-li-3">
          Directly and indirectly from activity on our Website, Mobile App, or Social Networks
        </li>
        <li id="ccpa-notice-ul-1-li-4">
          Through passive collection of information about your interactions, including page clicks, time spent, or other
          automatically collected meta-data Internet cookies
        </li>
        <li id="ccpa-notice-ul-1-li-5">Government Agencies and other publicly available sources</li>
      </ul>
      <h3>USE OF PERSONAL INFORMATION</h3>
      <p id="ccpa-notice-p8">
        We may use or disclose the Personal Information we collect for one or more of the following business purposes:
      </p>
      <ul id="ccpa-notice-ul-2">
        <li id="ccpa-notice-ul-2-li-1">
          To provide you with information, products, loans, or services that you request from us, including maintaining
          or servicing accounts, providing customer service, processing transactions, verifying customer identity,
          processing payments, or providing similar services
        </li>
        <li id="ccpa-notice-ul-2-li-2">
          Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and
          prosecuting those responsible for that activity
        </li>
        <li id="ccpa-notice-ul-2-li-3">To maintain, improve, upgrade, or enhance our products and services</li>
        <li id="ccpa-notice-ul-2-li-4">
          To carry out our obligations and enforce our rights arising from any contracts entered between you and us,
          including billing and collections
        </li>
        <li id="ccpa-notice-ul-2-li-5">
          Complying with laws and regulations and to comply with other legal process and law enforcement requirements
          (including any internal policy based on or reflecting legal or regulatory guidance, codes, or opinions)
        </li>
      </ul>
      <h3 id="ccpa-notice-subheading-5">SHARING PERSONAL INFORMATION</h3>
      <p id="ccpa-notice-p9">
        We may share your Personal Information and Sensitive Personal Information by disclosing it to a third party for
        a business purpose. In the preceding twelve (12) months, we have disclosed Personal Information and Sensitive
        Personal Information for a business purpose to the categories of third parties indicated in the charts below. We
        do not sell Personal Information or Sensitive Personal Information. In the preceding twelve (12) months, we have
        not sold the following categories of Personal Information or Sensitive Personal Information to the categories of
        third parties indicated in the charts below.
      </p>
      <p id="ccpa-notice-p10">
        <strong>Personal Information: </strong>
      </p>
      <table className="table table-bordered table-responsive" id="ccpa-notice-t3">
        <thead className="text-center" id="ccpa-notice-t3-h">
          <tr>
            <th id="ccpa-notice-t3-h1">Personal Information Category</th>
            <th id="ccpa-notice-t3-h2">Categories of Third-Party Recipients and Business Purpose Disclosures</th>
          </tr>
        </thead>
        <tbody>
          <tr id="ccpa-notice-t3-tr1">
            <td id="ccpa-notice-t3-tr1-td1">A: Identifiers.</td>
            <td id="ccpa-notice-t3-tr1-td2">
              Vendors and Service Providers who provide services such as website hosting, data analysis, payment,
              account opening and transaction processing, check order fulfillment, customer service, email delivery,
              auditing, regulatory compliance and marketing; governmental entities for regulatory compliance purposes
            </td>
          </tr>
          <tr id="ccpa-notice-t3-tr2">
            <td id="ccpa-notice-t3-tr2-td1">B: California Customer Records Personal Information categories.</td>
            <td id="ccpa-notice-t3-tr2-td2">
              Vendors and Service Providers who provide services such as website hosting, data analysis, payment,
              account opening and transaction processing, check order fulfillment, customer service, email delivery,
              auditing, regulatory compliance and marketing; governmental entities for regulatory compliance purposes
            </td>
          </tr>
          <tr id="ccpa-notice-t3-tr3">
            <td id="ccpa-notice-t3-tr3-td1">
              C: Protected classification characteristics under California or federal law.
            </td>
            <td id="ccpa-notice-t3-tr3-td2">
              Vendors and Service Providers who provide services such as compliance monitoring, data analysis, payment
              processing, account opening and transaction processing, customer service, auditing, and marketing;
              governmental entities for regulatory compliance purposes
            </td>
          </tr>
          <tr id="ccpa-notice-t3-tr4">
            <td id="ccpa-notice-t3-tr4-td1">D: Commercial information.</td>
            <td id="ccpa-notice-t3-tr4-td2">
              Vendors and Service Providers who provide services such as compliance monitoring, website hosting, data
              analysis, customer service, email delivery, auditing, regulatory compliance and marketing; governmental
              entities for regulatory compliance purposes
            </td>
          </tr>
          <tr id="ccpa-notice-t3-tr5">
            <td id="ccpa-notice-t3-tr5-td1">E: Biometric information.</td>
            <td id="ccpa-notice-t3-tr5-td2">
              Vendors and Service Providers who provide services such as website hosting, data analysis, identity
              verification, account opening and transaction processing, customer service, email delivery, auditing,
              regulatory compliance and marketing; governmental entities for regulatory compliance purposes
            </td>
          </tr>
          <tr id="ccpa-notice-t3-tr6">
            <td id="ccpa-notice-t3-tr6-td1">F: Internet or other similar network activity.</td>
            <td id="ccpa-notice-t3-tr6-td2">
              Vendors and Service Providers who provide services such as website hosting, data analysis, identity
              verification, account opening and transaction processing, customer service, email delivery, auditing,
              regulatory compliance and marketing; governmental entities for regulatory compliance purposes
            </td>
          </tr>
          <tr id="ccpa-notice-t3-tr7">
            <td id="ccpa-notice-t3-tr7-td1">G: Geolocation data.</td>
            <td id="ccpa-notice-t3-tr7-td2">
              Vendors and Service Providers who provide services such as website hosting, data analysis, account opening
              customer service, auditing, and marketing
            </td>
          </tr>
          <tr id="ccpa-notice-t3-tr8">
            <td id="ccpa-notice-t3-tr8-td1">H: Sensory data.</td>
            <td id="ccpa-notice-t3-tr8-td2">
              Vendors and Service Providers who provide services such as website hosting, data analysis, customer
              service, and identity verification
            </td>
          </tr>
          <tr id="ccpa-notice-t3-tr9">
            <td id="ccpa-notice-t3-tr9-td1">I: Professional or employment-related information.</td>
            <td id="ccpa-notice-t3-tr9-td2">
              Vendors and Service Providers who provide services such as website hosting, data analysis, payment,
              account opening and transaction processing, customer service, email delivery, auditing, and marketing;
              governmental entities for regulatory compliance purposes
            </td>
          </tr>
          <tr id="ccpa-notice-t3-tr10">
            <td id="ccpa-notice-t3-tr10-td1">J: Non-public education information.</td>
            <td id="ccpa-notice-t3-tr10-td2">We do not share this category of information</td>
          </tr>
          <tr id="ccpa-notice-t3-tr11">
            <td id="ccpa-notice-t3-tr11-td1">K: Inferences drawn from other personal information.</td>
            <td id="ccpa-notice-t3-tr11-td2">
              Vendors and Service Providers who provide services such as website hosting, data analysis, customer
              service, auditing, and marketing, identity verification, risk management, regulatory compliance;
              governmental entities for regulatory compliance purposes
            </td>
          </tr>
        </tbody>
      </table>
      <p id="ccpa-notice-p11">
        <strong>Sensitive Personal Information:</strong>
      </p>
      <table className="table table-bordered table-responsive" id="ccpa-notice-t4">
        <thead className="text-center" id="ccpa-notice-t4-h">
          <tr>
            <th id="ccpa-notice-t4-h1">Personal Information Category</th>
            <th id="ccpa-notice-t4-h2">Categories of Third-Party Recipients and Business Purpose Disclosures</th>
          </tr>
        </thead>
        <tbody>
          <tr id="ccpa-notice-t4-tr1">
            <td id="ccpa-notice-t4-tr1-td1">
              A. Social security, driver's license, state identification card, or passport number.
            </td>
            <td id="ccpa-notice-t4-tr1-td2">
              Vendors and Service Providers who provide services such as payment processing, client onboarding and
              transaction processing, customer service, identity verification, email delivery, auditing, regulatory
              compliance and marketing; governmental entities for regulatory compliance purposes
            </td>
          </tr>
          <tr id="ccpa-notice-t4-tr2">
            <td id="ccpa-notice-t4-tr2-td1">
              B. Account log-in, financial account, debit card, or credit card number in combination with any required
              security or access code, password, or credentials allowing access to an account.
            </td>
            <td id="ccpa-notice-t4-tr2-td2">
              Vendors and Service Providers who provide services such as online and mobile banking, identity
              verification, data analysis, payment processing, account opening and transaction processing, check order
              fulfillment, customer service, regulatory compliance and auditing; governmental entities for regulatory
              compliance purposes
            </td>
          </tr>
          <tr id="ccpa-notice-t4-tr3">
            <td id="ccpa-notice-t4-tr3-td1">C. Precise geolocation.</td>
            <td id="ccpa-notice-t4-tr3-td2">We do not share this category of information</td>
          </tr>
          <tr id="ccpa-notice-t4-tr4">
            <td id="ccpa-notice-t4-tr4-td1">
              D. Racial or ethnic origin, religious or philosophical beliefs, or union membership.
            </td>
            <td id="ccpa-notice-t4-tr4-td2">Governmental entities for regulatory compliance purposes</td>
          </tr>
          <tr id="ccpa-notice-t4-tr5">
            <td id="ccpa-notice-t4-tr5-td1">
              E. Contents of a consumer's mail, email, and text messages unless the business is the intended recipient
              of the communication.
            </td>
            <td id="ccpa-notice-t4-tr5-td2">We do not collect this category of information</td>
          </tr>
          <tr id="ccpa-notice-t4-tr6">
            <td id="ccpa-notice-t4-tr6-td1">F. Genetic data.</td>
            <td id="ccpa-notice-t4-tr6-td2">We do not collect this category of information</td>
          </tr>
          <tr id="ccpa-notice-t4-tr7">
            <td id="ccpa-notice-t4-tr7-td1">
              G. The processing of biometric information for the purpose of uniquely identifying a consumer.
            </td>
            <td id="ccpa-notice-t4-tr7-td2">
              Vendors and Service Providers who provide services such as data analysis, identity verification, payment
              processing, transaction processing, customer service, auditing, and marketing
            </td>
          </tr>
          <tr id="ccpa-notice-t4-tr8">
            <td id="ccpa-notice-t4-tr8-td1">
              H. Personal Information collected and analyzed concerning a consumer's health.
            </td>
            <td id="ccpa-notice-t4-tr8-td2">We do not collect this category of information</td>
          </tr>
          <tr id="ccpa-notice-t4-tr9">
            <td id="ccpa-notice-t4-tr9-td1">
              I. Personal Information collected and analyzed concerning a consumer's sex life or sexual orientation.
            </td>
            <td id="ccpa-notice-t4-tr9-td2">We do not collect this category of information</td>
          </tr>
        </tbody>
      </table>
      <h3 id="ccpa-notice-subheading-6">YOUR RIGHTS UNDER THE CCPA</h3>
      <p id="ccpa-notice-p12">
        The CCPA provides California residents with specific rights regarding their Personal Information. This section
        describes your CCPA rights and explains how to exercise those rights.
      </p>
      <p id="ccpa-notice-p13">
        <strong>Right to Know:</strong> You have the right to request that we disclose certain information to you about
        our collection and use of your Personal Information over the past 12 months. Once we receive your request and
        confirm your identity (see Exercising Your Rights to Know, Delete, and Correct), we will disclose to you:
      </p>
      <ul id="ccpa-notice-ul-3">
        <li id="ccpa-notice-ul-3-li-1">
          The specific pieces of Personal Information we collected about you (also called a data portability request)
        </li>
        <li id="ccpa-notice-ul-3-li-2">The categories of Personal Information we collected about you</li>
        <li id="ccpa-notice-ul-3-li-3">
          The categories of sources for the Personal Information we collected about you
        </li>
        <li id="ccpa-notice-ul-3-li-4">
          The Personal Information categories sold or shared, if any, and the categories of third parties purchasing or
          receiving that Personal Information
        </li>
        <li id="ccpa-notice-ul-3-li-5">
          The Personal Information categories disclosed for a business purpose, if any, and the categories of persons
          receiving the Personal Information
        </li>
        <li id="ccpa-notice-ul-3-li-6">
          Our business or commercial purpose for collecting, selling, or sharing that Personal Information
        </li>
      </ul>
      <p id="ccpa-notice-p14">
        <strong>Right to Delete:</strong> You have the right to request that we delete any of your Personal Information
        that we collected from you and retained, subject to certain exceptions. Once we receive your request and confirm
        your identity (see Exercising Your Rights to Know, Delete, and Correct), we will review your request to see if
        an exception allowing us to retain the information applies. We may deny your deletion request if retaining the
        information is necessary for us or our service provider(s) to:
      </p>
      <ul id="ccpa-notice-ul-4">
        <li id="ccpa-notice-ul-4-li-1">
          Complete the transaction for which the Personal Information was collected, fulfill the terms of a written
          warranty or product recall conducted in accordance with federal law, provide a good or service requested by
          you, or reasonably anticipated by you within the context of our ongoing business relationship with you, or
          otherwise perform a contract you have entered into with us.
        </li>
        <li id="ccpa-notice-ul-4-li-2">
          Help ensure security and integrity to the extent the use of your Personal Information is reasonably necessary
          and proportionate for those purposes.
        </li>
        <li id="ccpa-notice-ul-4-li-3">
          Debug products to identify and repair errors that impair existing intended functionality.
        </li>
        <li id="ccpa-notice-ul-4-li-4">
          Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise
          another right provided for by law.
        </li>
        <li id="ccpa-notice-ul-4-li-5">
          Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).
        </li>
        <li id="ccpa-notice-ul-4-li-6">
          Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that
          adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render
          impossible or seriously impair the research's achievement, if you previously provided informed consent.
        </li>
        <li id="ccpa-notice-ul-4-li-7">
          Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship
          with us.
        </li>
        <li id="ccpa-notice-ul-4-li-8">Comply with a legal obligation.</li>
      </ul>
      <p id="ccpa-notice-p15">
        <strong>Right to Correct:</strong> You have the right to request that we correct any inaccuracies in your
        Personal Information we hold about you, considering both the Personal Information's nature and processing
        purposes. Upon receiving your request (see Exercising Your Rights to Know, Delete, and Correct), we will use
        commercially reasonable efforts to:
      </p>
      <ul id="ccpa-notice-ul-5">
        <li id="ccpa-notice-ul-5-li-1">
          Correct the Personal Information on our existing systems or alternatively, delete the contested Personal
          Information if the deletion does not negatively impact you or if you consent to such deletion.
        </li>
      </ul>
      <p id="ccpa-notice-p16">
        <strong>Right to Opt-Out:</strong> You have the right to prevent the sale and sharing of your Personal
        Information to third parties at any time by requesting that we stop doing so (see Exercising Your Rights to
        Opt-Out).
      </p>
      <p id="ccpa-notice-p17">
        <strong>Right to Limit Sensitive Personal Information:</strong> You have the right to limit how we use and
        disclose your Sensitive Personal Information by requesting that we restrict our use and disclosure of such
        Sensitive Personal Information to the "Permitted Sensitive Personal Information Purposes", which allow us to
        collect, use, and disclose Sensitive Personal Information for the following purposes, even after you exercise
        your limitation rights:
      </p>
      <ul id="ccpa-notice-ul-6">
        <li id="ccpa-notice-ul-6-li-1">
          To perform services or provide goods that an average consumer requesting those goods or services would
          reasonably expect.
        </li>
        <li id="ccpa-notice-ul-6-li-2">
          To prevent, detect, and investigate security incidents compromising Personal Information.
        </li>
        <li id="ccpa-notice-ul-6-li-3">
          To resist malicious, deceptive, fraudulent, or illegal actions directed at our business and to prosecute those
          responsible for those actions.
        </li>
        <li id="ccpa-notice-ul-6-li-4">To ensure individuals' physical safety.</li>
        <li id="ccpa-notice-ul-6-li-5">
          <p>
            For short-term, transient use, including, but not limited to, non-personalized advertising shown as part of
            your current interaction with us, so long as we do not:
          </p>
          <ul id="ccpa-notice-ul-6-li-5-ul">
            <li id="ccpa-notice-ul-6-li-5-ul-li-1">
              disclose your Sensitive Personal Information to another third party; or
            </li>
            <li id="ccpa-notice-ul-6-li-5-ul-li-2">
              use it to build a profile about you or otherwise alter your experience outside your current interaction
              with us.
            </li>
          </ul>
        </li>
        <li id="ccpa-notice-ul-6-li-6">
          To perform services for the business, including maintaining or servicing accounts, providing customer service,
          processing or fulfilling orders and transactions, verifying customer information, processing payments,
          providing financing, providing analytic services, providing, storage, or providing similar services for the
          business.
        </li>
        <li id="ccpa-notice-ul-6-li-7">
          <p>For products, services, or devices that we own, manufacture (directly or indirectly), or control, to:</p>
          <ul id="ccpa-notice-ul-6-li-7-ul">
            <li id="ccpa-notice-ul-6-li-7-ul-li-1">
              verify or maintain the quality or safety of the product, service, or device; or
            </li>
            <li id="ccpa-notice-ul-6-li-7-ul-li-2">improve, upgrade, or enhance the service or device.</li>
          </ul>
        </li>
        <li id="ccpa-notice-ul-6-li-8">For purposes that do not infer characteristics about you.</li>
      </ul>
      <p id="ccpa-notice-p18">
        <strong>Right to Non-Discrimination:</strong> We will not discriminate against you for exercising any of your
        CCPA rights. Unless permitted by the CCPA, we will not:
      </p>
      <ul id="ccpa-notice-ul-7">
        <li id="ccpa-notice-ul-7-li-1">Deny you goods or services.</li>
        <li id="ccpa-notice-ul-7-li-2">
          Charge you different prices or rates for goods or services, including through granting discounts or other
          benefits, or imposing penalties.
        </li>
        <li id="ccpa-notice-ul-7-li-3">Provide you a different level or quality of goods or services.</li>
      </ul>
      <h3 id="ccpa-notice-subheading-7">EXERCISING YOUR RIGHTS</h3>
      <p id="ccpa-notice-p19">
        <strong>Right to Know, Delete, and Correct:</strong> To exercise your rights to know, delete, or correct
        described above, please submit a request by either:
      </p>
      <ul id="ccpa-notice-ul-8">
        <li id="ccpa-notice-ul-8-li-1">
          <a href="tel:800-324-9375" id="c3-tel-link-1">
            800-324-9375
          </a>
        </li>
        <li id="ccpa-notice-ul-8-li-2">
          Completing a form online at:{" "}
          <Link to="/privacy-center#ccpa-request" id="ccpa-request-form-link-1">
            www.wafdbank.com/privacy-center#ccpa-request
          </Link>
        </li>
      </ul>
      <p id="ccpa-notice-p20">
        <strong>Right to Opt-Out:</strong> To exercise your right to opt-out, please submit a request by either:
      </p>
      <ul id="ccpa-notice-ul-9">
        <li id="ccpa-notice-ul-9-li-1">
          <a href="tel:800-324-9375" id="c3-tel-link-2">
            800-324-9375
          </a>
        </li>
        <li id="ccpa-notice-ul-9-li-2">
          <Link to="/privacy-center#ccpa-request" id="ccpa-request-form-link-2">
            www.wafdbank.com/privacy-center#ccpa-request
          </Link>
        </li>
      </ul>
      <p id="ccpa-notice-p21">
        <strong>Right to Limit Sensitive Personal Information:</strong> To exercise your right to limit Sensitive
        Personal Information, please submit a request by either:
      </p>
      <ul id="ccpa-notice-ul-10">
        <li id="ccpa-notice-ul-10-li-1">
          <a href="tel:800-324-9375" id="c3-tel-link-3">
            800-324-9375
          </a>
        </li>
        <li id="ccpa-notice-ul-10-li-2">
          <Link to="/privacy-center#ccpa-request" id="ccpa-request-form-link-3">
            www.wafdbank.com/privacy-center#ccpa-request
          </Link>
        </li>
      </ul>
      <p id="ccpa-notice-p22">
        <strong>Submission and Response</strong>
      </p>
      <p id="ccpa-notice-p23">
        Once you have submitted a request, we will acknowledge receipt of your request and advise you how long we expect
        it will take to respond if we are able to verify your identity. Also, we may ask you to describe your request
        with sufficient detail that allows us to properly understand, evaluate, and respond to it. Only you or a person
        that you authorize to act on your behalf, may make a Verifiable Consumer Request, as defined in the CCPA,
        related to your Personal Information. You may also make a Verifiable Consumer Request on behalf of your minor
        child.
      </p>
      <p id="ccpa-notice-p24">
        We cannot respond to your request or provide you with Personal Information if we cannot verify your identity or
        authority to make the request and confirm the Personal Information relates to you. Additionally, we will not
        honor your request where an exception applies, such as where the disclosure of Personal Information would
        adversely affect the rights and freedoms of another consumer or where the Personal Information that we maintain
        about you is not subject to the CCPA's access or deletion rights. We will advise you in our response if we are
        not able to honor your request. We will not provide social security numbers, driver's license numbers or
        government issued identification numbers, financial account numbers, account passwords or security questions and
        answers, or any specific pieces of information if the disclosure presents the possibility of unauthorized access
        that could result in identity theft or fraud or unreasonable risk to data or systems and network security.
      </p>
      <p id="ccpa-notice-p25">
        Making a Verifiable Consumer Request does not require you to create an account with us. We will only use
        Personal Information provided in a Verifiable Consumer Request to verify the requestor's identity or authority
        to make the request.
      </p>
      <p id="ccpa-notice-p26">
        We commit to respond to a Verifiable Consumer Request within 45 days of its receipt. If we require more time (up
        to a total of ninety (90) days), we will inform you of the reason and extension period in writing.
      </p>
      <p id="ccpa-notice-p27">
        <strong>Authorized Agent</strong>
      </p>
      <p id="ccpa-notice-p28">
        You may authorize an agent to exercise your rights on your behalf. When a request is submitted by an authorized
        agent, WaFd Bank will require the requestor to: (i) provide the authorized agent's written permission to do so;
        and (ii) will require the requestor to verify their own identity directly with WaFd Bank. If WaFd Bank is unable
        to verify the identity of the requestor or does not receive proof from the authorized agent that the requestor
        authorized the agent to act on the requestor's behalf, the request will be denied.
      </p>
      <h3>CHANGES TO THIS CCPA NOTICE</h3>
      <p id="ccpa-notice-p29">
        WaFd Bank may make periodic changes to this CCPA Notice. When these changes occur, we will post the new CCPA
        Notice on our website and change the "Last Updated" date. When appropriate, we may notify you through other
        means.
      </p>
      <h3 id="ccpa-notice-subheading-8">CONTACT FOR MORE INFORMATION</h3>
      <p id="ccpa-notice-p30">If you have any questions about this policy, please contact us at:</p>
      <p id="ccpa-notice-p31">
        E-mail:{" "}
        <a href="mailto:privacy@wafd.com" id="privacy-mail-link-2">
          privacy@wafd.com
        </a>
        <br />
        Address: 425 Pike Street, Seattle, WA 98101
        <br />
        Toll Free Number:{" "}
        <a href="tel:800-324-9375" id="c3-tel-link-4">
          800-324-9375
        </a>
      </p>
      <p id="ccpa-notice-last-updated-date">Last Updated: November, 2023</p>
    </>
  );
};

export default CCPANoticeData;
