import React from "react";
import { Link } from "gatsby";
import Icon from "../custom-widgets/icon";

const ProtectingYourPrivacyData = () => (
  <>
    <h1 id="protecting-your-privacy-header">California Consumer Privacy Act Notice at Collection</h1>
    <p>
      <a
        href="/documents/privacy/ccpa-notice-at-collection.pdf"
        className="text-decoration-none"
        id="ccpa-notice-at-collection-pdf"
        target="_blank"
      >
        <Icon name="file-pdf" class="mr-1" />
        Printer friendly version of Protecting Your Privacy
      </a>
    </p>
    <p id="protecting-your-privacy-p1">
      Washington Federal Bank ("we", "us", or "WaFd Bank") is collecting your personal information and sensitive
      personal information to support its business operations, including for the business purposes listed in the chart
      below.
    </p>
    <p id="protecting-your-privacy-p2">
      We do not sell personal information, including any sensitive personal information. To view our full Privacy
      Policy, visit{" "}
      <Link to="/privacy-center#privacy-policy" id="privacy-policy-link-3">
        www.wafdbank.com/privacy-center#privacy-policy
      </Link>
      . You may also review and download our California Consumer Privacy Act Notice, which applies to California
      residents, by visiting{" "}
      <Link to="/privacy-center#ccpa-notice" id="ccpa-request-form-link-3">
        www.wafdbank.com/privacy-center#ccpa-notice
      </Link>
      .
    </p>
    <p id="protecting-your-privacy-p3">
      We may collect the personal information and sensitive personal information categories listed in the tables below.
      The tables also list, for each category, our expected retention period, use purposes, and whether we sell the
      information or share it with third parties for cross-context behavioral advertising.
    </p>
    <p id="protecting-your-privacy-p4">
      As to all information collected as disclosed in this notice, WaFd does not retain Personal Information or
      Sensitive Personal Information for each disclosed purpose for which the Personal Information was collected for
      longer than is reasonably necessary for that purpose or as required by law.
    </p>
    <table className="table table-bordered table-responsive" id="protecting-your-privacy-t1">
      <thead className="text-center" id="protecting-your-privacy-t1-h">
        <tr>
          <th id="protecting-your-privacy-t1-h1">Personal Information Category</th>
          <th id="protecting-your-privacy-t1-h2">Business Purpose</th>
          <th className="w-25" id="protecting-your-privacy-t1-h3">
            Sold or Shared For Cross-Context Behavioral Advertising
          </th>
        </tr>
      </thead>
      <tbody>
        <tr id="protecting-your-privacy-t1-tr1">
          <td id="protecting-your-privacy-t1-tr1-td1">
            <strong>Identifiers</strong>, such as real name, alias, postal address, unique personal identifier, online
            identifier, Internet Protocol address, email address, account name, Social Security number, driver's license
            number, passport number, or other similar identifiers.
          </td>
          <td id="protecting-your-privacy-t1-tr1-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Marketing efforts</li>
              <li>Verifying consumer identities</li>
              <li>Regulatory compliance</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t1-tr1-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t1-tr2">
          <td id="protecting-your-privacy-t1-tr2-td1">
            <strong>California Customer Records personal information</strong>, which includes a name, signature, Social
            Security number, physical characteristics or description, address, telephone number, passport number,
            driver's license or state identification card number, insurance policy number, education, employment,
            employment history, bank account number, credit card number, debit card number, or any other financial
            information, medical information, or health insurance information.
          </td>
          <td id="protecting-your-privacy-t1-tr2-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Marketing efforts</li>
              <li>Verifying consumer identities</li>
              <li>Regulatory compliance</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t1-tr2-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t1-tr3">
          <td id="protecting-your-privacy-t1-tr3-td1">
            <strong>Protected classification characteristics under California or federal law</strong>, such as age (40
            years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status,
            medical condition, physical or mental disability, sex (including gender, gender identity, gender expression,
            pregnancy or childbirth and related medical conditions), sexual orientation, reproductive health
            decision-making, military and veteran status, or genetic information (including familial genetic
            information).
          </td>
          <td id="protecting-your-privacy-t1-tr3-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Verifying consumer identities</li>
              <li>Regulatory compliance</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t1-tr3-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t1-tr4">
          <td id="protecting-your-privacy-t1-tr4-td1">
            <strong>Commercial information</strong>, such as records of personal property, products or services
            purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.
          </td>
          <td id="protecting-your-privacy-t1-tr4-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Verifying consumer identities</li>
              <li>Regulatory compliance</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t1-tr4-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t1-tr5">
          <td id="protecting-your-privacy-t1-tr5-td1">
            <strong>Biometric information</strong>, such as genetic, physiological, behavioral, and biological
            characteristics, or activity patterns used to extract a template or other identifier or identifying
            information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or
            other physical patterns, and sleep, health, or exercise data.
          </td>
          <td id="protecting-your-privacy-t1-tr5-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Verifying consumer identities</li>
              <li>Regulatory compliance</li>
              <li>Detecting/preventing security incidents</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t1-tr5-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t1-tr6">
          <td id="protecting-your-privacy-t1-tr6-td1">
            <strong>Internet or other similar network activity</strong>, such as browsing history, search history,
            information on a consumer's interaction with a website, application, or advertisement.
          </td>
          <td id="protecting-your-privacy-t1-tr6-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Verifying consumer identities</li>
              <li>Regulatory compliance</li>
              <li>Detecting/preventing security incidents</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t1-tr6-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t1-tr7">
          <td id="protecting-your-privacy-t1-tr7-td1">
            <strong>Geolocation data</strong>, such as physical location or movements.
          </td>
          <td id="protecting-your-privacy-t1-tr7-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Verifying consumer identities</li>
              <li>Regulatory compliance</li>
              <li>Detecting/preventing security incidents</li>
              <li>Fraud detection</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t1-tr7-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t1-tr8">
          <td id="protecting-your-privacy-t1-tr8-td1">
            <strong>Sensory data</strong>, such as audio, electronic, visual, thermal, olfactory, or similar
            information.
          </td>
          <td id="protecting-your-privacy-t1-tr8-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Verifying consumer identities</li>
              <li>Regulatory compliance</li>
              <li>Detecting/preventing security incidents</li>
              <li>Monitoring customer service</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t1-tr8-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t1-tr9">
          <td id="protecting-your-privacy-t1-tr9-td1">
            <strong>Professional or employment-related information</strong>, such as current or past job history or
            performance evaluations.
          </td>
          <td id="protecting-your-privacy-t1-tr9-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Verifying consumer identities</li>
              <li>Regulatory compliance</li>
              <li>Detecting/preventing security incidents</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t1-tr9-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t1-tr10">
          <td id="protecting-your-privacy-t1-tr10-td1">
            <strong>Non-public education information</strong>, which may include education records directly related to a
            student maintained by an educational institution or party acting on its behalf, such as grades, transcripts,
            class lists, student schedules, student identification codes, student financial information, or student
            disciplinary records.
          </td>
          <td id="protecting-your-privacy-t1-tr10-td2">Verifying consumer identities</td>
          <td id="protecting-your-privacy-t1-tr10-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t1-tr11">
          <td id="protecting-your-privacy-t1-tr11-td1">
            <strong>Inferences drawn from other personal information</strong>, such as a profile reflecting a person's
            preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence,
            abilities, and aptitudes.
          </td>
          <td id="protecting-your-privacy-t1-tr11-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Marketing efforts</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t1-tr11-td3">Not Sold or Shared</td>
        </tr>
      </tbody>
    </table>
    <p id="protecting-your-privacy-p5">
      Sensitive personal information is a subtype of personal information consisting of specific information categories.
      We may collect or use information that falls within the sensitive personal information categories listed in the
      table below to infer characteristics about a person.
    </p>

    <table className="table table-bordered table-responsive" id="protecting-your-privacy-t2">
      <thead className="text-center" id="protecting-your-privacy-t3-h">
        <tr>
          <th id="protecting-your-privacy-t2-h1">Sensitive Personal Information Category</th>
          <th id="protecting-your-privacy-t2-h2">Business Purpose</th>
          <th id="protecting-your-privacy-t2-h3">Sold or Shared For Cross-Context Behavioral Advertising</th>
        </tr>
      </thead>
      <tbody>
        <tr id="protecting-your-privacy-t2-tr1">
          <td id="protecting-your-privacy-t2-tr1-td1">
            <strong>Government identifiers</strong>, such as your Social Security number, driver's license, state
            identification card, or passport number.
          </td>
          <td id="protecting-your-privacy-t2-tr1-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Marketing efforts</li>
              <li>Verifying consumer identities</li>
              <li>Regulatory compliance</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t2-tr1-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t2-tr2">
          <td id="protecting-your-privacy-t2-tr2-td1">
            <strong>Complete account access credentials</strong>, such as user names, account numbers, or card numbers
            combined with required access/security code or password.
          </td>
          <td id="protecting-your-privacy-t2-tr2-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Marketing efforts</li>
              <li>Verifying consumer identities</li>
              <li>Regulatory compliance</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t2-tr2-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t2-tr3">
          <td id="protecting-your-privacy-t2-tr3-td1">
            Precise geolocation, such as your physical location within a small area (1,850 feet radius).
          </td>
          <td id="protecting-your-privacy-t2-tr3-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Marketing efforts</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t2-tr3-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t2-tr4">
          <td id="protecting-your-privacy-t2-tr4-td1">Racial or ethnic origin.</td>
          <td id="protecting-your-privacy-t2-tr4-td2">
            <ul>
              <li>Regulatory compliance</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t2-tr4-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t2-tr5">
          <td id="protecting-your-privacy-t2-tr5-td1">Religious or philosophical beliefs.</td>
          <td colspan="2" id="protecting-your-privacy-t2-tr5-td2">
            We do not collect this category of information
          </td>
        </tr>
        <tr id="protecting-your-privacy-t2-tr6">
          <td id="protecting-your-privacy-t2-tr6-td1">Union membership.</td>
          <td colspan="2" id="protecting-your-privacy-t2-tr6-td2">
            We do not collect this category of information
          </td>
        </tr>
        <tr id="protecting-your-privacy-t2-tr7">
          <td id="protecting-your-privacy-t2-tr7-td1">Mail, email or text message contents not directed to us.</td>
          <td colspan="2" id="protecting-your-privacy-t2-tr7-td2">
            We do not collect this category of information
          </td>
        </tr>
        <tr id="protecting-your-privacy-t2-tr8">
          <td id="protecting-your-privacy-t2-tr8-td1">Genetic data.</td>
          <td colspan="2" id="protecting-your-privacy-t2-tr8-td2">
            We do not collect this category of information
          </td>
        </tr>
        <tr id="protecting-your-privacy-t2-tr9">
          <td id="protecting-your-privacy-t2-tr9-td1">Unique biometric information used for identification.</td>
          <td id="protecting-your-privacy-t2-tr9-td2">
            <ul>
              <li>Offering financial products or services</li>
              <li>Verifying consumer identities</li>
              <li>Regulatory compliance</li>
              <li>Detecting/preventing security incidents</li>
            </ul>
          </td>
          <td id="protecting-your-privacy-t2-tr9-td3">Not Sold or Shared</td>
        </tr>
        <tr id="protecting-your-privacy-t2-tr10">
          <td id="protecting-your-privacy-t2-tr10-td1">Health information.</td>
          <td colspan="2" id="protecting-your-privacy-t2-tr10-td1">
            We do not collect this category of information
          </td>
        </tr>
        <tr id="protecting-your-privacy-t2-tr11">
          <td id="protecting-your-privacy-t2-tr11-td1">Sex life, or sexual orientation information.</td>
          <td colspan="2" id="protecting-your-privacy-t2-tr11-td2">
            We do not collect this category of information
          </td>
        </tr>
      </tbody>
    </table>
    <p id="protecting-your-privacy-p6">
      If you have any questions about this Notice or need to access it in an alternative format due to having a
      disability, please contact us at{" "}
      <a href="mailto:privacy@wafd.com" id="privacy-mail-link-1">
        privacy@wafd.com
      </a>{" "}
      or{" "}
      <a href="tel:800-324-9375" id="c3-tel-link-5">
        800-324-9375
      </a>
      .
    </p>
    <p id="protecting-your-privacy-last-updated-date">Last Updated: November, 2023</p>
  </>
);

export default ProtectingYourPrivacyData;
