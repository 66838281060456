import React, { useRef, useEffect } from "react";
import { useLocation } from "@reach/router";
import useCheckMktoFormIsSubmitted from "../../hooks/use-check-mkto-form-is-submitted";
import NotificationAlert from "../../components/notifications/notification-alert";

const CCPARequestData = () => {
  const isMktoFormSubmitted = useCheckMktoFormIsSubmitted();

  const mkto = useRef(null);
  let pageLocation = useLocation();
  let hash = useLocation().hash;

  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://app-sj21.marketo.com/js/forms2/js/forms2.min.js";
    scriptTag.id = "mkto-script";
    scriptTag.async = true;

    if (typeof window !== "undefined") {
      const ccpaRequestSent = localStorage.getItem("ccpaRequestSent");
      if (ccpaRequestSent) {
        let pageOrigin = pageLocation.origin;
        // console.log("ORIGIN:", pageOrigin);

        let pagePathname = pageLocation.pathname;
        // console.log("PATHNAME:", pagePathname);

        let cleanedLink = `${pageOrigin}${pagePathname}${hash}`;
        // console.log("CLEANED LINK:", cleanedLink);
        window.history.replaceState(null, "", cleanedLink);
        window.location.href = cleanedLink;
        localStorage.removeItem("ccpaRequestSent");
      }

      if (isMktoFormSubmitted && !ccpaRequestSent) {
        // console.log("FORM SUBMITTED");
        localStorage.setItem("ccpaRequestSent", "true");
      }

      if (!document.getElementById("mkto-script")) {
        mkto?.current?.appendChild(scriptTag);
        const checkInterval = setInterval(() => {
          if (typeof MktoForms2 !== "undefined") {
            MktoForms2.loadForm("//316-DXP-546.mktoweb.com", "316-DXP-546", 1363);
            clearInterval(checkInterval);
          } else {
          }
        }, 500);
      }
    }
  }, [hash, isMktoFormSubmitted]);

  const ccpaAlertData = {
    type: "warning",
    bodyText: "CCPA requests may only be submitted for California residents.",
    id: "ccpa-notification-alert",
    class: "",
    showIcon: true
  };

  return (
    <>
      {isMktoFormSubmitted ? (
        <h4>
          Thank you for your CCPA request. We've received your form and are on it! Our team will review your submission
          and will be reaching out to you shortly. If you have any questions or need assistance, feel free to reach out
          to us at <a href="mailto:privacy@wafd.com">privacy@wafd.com</a>. Your privacy is our priority, and we're here
          to help every step of the way.
        </h4>
      ) : (
        <>
          <h1 id="ccpa-request-header">California Consumer Privacy Act (CCPA) Request</h1>
          <NotificationAlert {...ccpaAlertData} />
          <div ref={mkto} id="ccpa-request-matketo-form">
            <form id="mktoForm_1363"></form>
          </div>
        </>
      )}
    </>
  );
};

export default CCPARequestData;
