import React from "react";
import { Link } from "gatsby";
import Icon from "../custom-widgets/icon";

const PrivacyPolicyData = () => (
  <>
    <h1 id="privacy-policy-header">Privacy Policy</h1>
    <p>
      <a
        href="/documents/privacy/privacy-policy.pdf"
        className="text-decoration-none"
        id="privacy-policy-pdf"
        target="_blank"
      >
        <Icon name="file-pdf" class="mr-1" />
        Printer friendly version of Privacy Policy
      </a>
    </p>
    <p id="privacy-policy-revised-date">Revised: June, 2024</p>
    <p id="privacy-policy-p1">
      We respect your privacy and are committed to protecting it through our compliance with this Privacy Policy (this
      "Policy"). This Policy applies when you visit or use our website (https://www.wafdbank.com/), mobile applications,
      or any other online services offered by Washington Federal Bank ("WaFd Bank", "we" or "us") that link to or
      reference this Policy (collectively, the "Website"). This Policy also describes the types of information we may
      collect from you or that you may provide when you use the Website and our practices for collecting, using,
      maintaining, protecting, and disclosing that information.
    </p>
    <p id="privacy-policy-p2">
      <strong>This Policy applies to information we collect:</strong>
    </p>
    <ul id="privacy-policy-ul-1">
      <li id="privacy-policy-ul-1-li-1">On the Website.</li>
      <li id="privacy-policy-ul-1-li-2">
        Through mobile applications you download from the Website, which provide a dedicated non-browser based
        interaction between you and the Website.
      </li>
      <li id="privacy-policy-ul-1-li-3">
        When you interact with our advertising applications on third party websites and services, if those applications
        or advertisements include links to this policy.
      </li>
    </ul>
    <p id="privacy-policy-p3">
      Please read this Policy carefully to understand our policies and practices regarding your information and how we
      will treat it. If you do not agree with our policies and practices, your choice is not to use the Website. By
      accessing or using this Website, you agree to this Policy. This Policy may change from time to time. Your
      continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check
      the policy periodically for updates.
    </p>
    <h3 id="privacy-policy-subheading-1">Information We Collect</h3>
    <p id="privacy-policy-p4">
      We collect several types of information from and about users of our Website, including information:
    </p>
    <p id="privacy-policy-p5">
      By which you may be personally identified, such as name, postal address, email address, telephone number, social
      security number, existing account number, username, password or any other identifier by which you may be contacted
      online or offline ("personal information"). If you have a financial product or service with us, we will use any
      personal information that we collect from or about you in accordance with our{" "}
      <Link to="/privacy-center#privacy-policy" id="privacy-policy-link-1">
        www.wafdbank.com/privacy-center#privacy-policy
      </Link>
      .
    </p>
    <ul id="privacy-policy-ul-2">
      <li id="privacy-policy-ul-2-li-1">
        That is about you but individually does not identify you, such as aggregated, de-identified or demographic
        statistical data ("non-personal information").
      </li>
      <li id="privacy-policy-ul-2-li-2">
        About your internet connection, the equipment you use to access the Website, and usage details.
      </li>
    </ul>
    <p id="privacy-policy-p6">We collect this information:</p>
    <ul id="privacy-policy-ul-3">
      <li id="privacy-policy-ul-3-li-1">Directly from you when you provide it to use.</li>
      <li id="privacy-policy-ul-3-li-2">
        Automatically as you navigate through the Website. Information collected automatically may include usage
        details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.
      </li>
      <li id="privacy-policy-ul-3-li-3">From third parties, for example, our business partners.</li>
    </ul>
    <h3 id="privacy-policy-subheading-2">
      <em>Information You Provide to Us</em>
    </h3>
    <p id="privacy-policy-p7">The information we collect through the Website may include:</p>
    <ul id="privacy-policy-ul-4">
      <li id="privacy-policy-ul-4-li-1">
        Information that you provide by filling in forms on our Website. This includes information provided at the time
        of registering to use our Website, opening an account, or signing up for other services. We may also ask you for
        information when you report a problem with the Website.
      </li>
      <li id="privacy-policy-ul-4-li-2">Records and copies of your communications with us.</li>
      <li id="privacy-policy-ul-4-li-3">
        Your responses to surveys that we may ask you to complete for research purposes.
      </li>
      <li id="privacy-policy-ul-4-li-4">
        Contact information you provide when you elect to receive electronic statements, notices or disclosures (go
        paperless) or change your contact preferences.
      </li>
      <li id="privacy-policy-ul-4-li-5">
        Your email address, username, the last four digits of your social security number, answers to your security
        questions, and other account information used to verify your identity before executing your request to reset
        your password.
      </li>
      <li id="privacy-policy-ul-4-li-6">
        Information from your computer, or your smartphone, tablet or other mobile device (collectively, "devices").
      </li>
    </ul>
    <h3 id="privacy-policy-subheading-3">
      <em>Information We Collect Through Automatic Data Collection Technologies</em>
    </h3>
    <p id="privacy-policy-p8">
      As you navigate through and interact with the Website and third party links on the Website, we and third parties,
      including our affiliates and non-affiliated third party services providers (collectively, our "service
      providers"), may use automatic data collection technologies to collect non-personal information about your
      equipment, browsing actions, and patterns, including:
    </p>
    <ul id="privacy-policy-ul-5">
      <li id="privacy-policy-ul-5-li-1">
        Details of your visits to our Website, including traffic data, location data, logs, and other communication data
        and the resources that you access and use on the Website.
      </li>
      <li id="privacy-policy-ul-5-li-2">
        Information about your computer and internet connection, including your IP address, operating system, and
        browser type.
      </li>
    </ul>
    <p id="privacy-policy-p9">
      We also may use these technologies to collect information about your online activities over time and across
      third-party websites or other online services (behavioral tracking). Click here{" "}
      <Link to="/privacy-center#ccpa-request" id="ccpa-request-form-link">
        www.wafdbank.com/privacy-center#ccpa-request
      </Link>{" "}
      for information on how you can opt out of behavioral tracking on this Website and how we respond to web browser
      signals and other mechanisms that enable consumers to exercise choice about behavioral tracking.
    </p>
    <p id="privacy-policy-p10">
      Non-personal information may be aggregated in our server logs for various purposes, including collection of device
      event information such as browser crashes, system activity and referral website URL.
    </p>
    <p id="privacy-policy-p11">
      The information we collect automatically helps us to improve the Website and to deliver a better and more
      personalized service, including by enabling us to:
    </p>
    <ul id="privacy-policy-ul-6">
      <li id="privacy-policy-ul-6-li-1">Estimate our audience size and usage patterns.</li>
      <li id="privacy-policy-ul-6-li-2">
        Store information about your preferences, allowing us to customize the Website according to your individual
        interests.
      </li>
      <li id="privacy-policy-ul-6-li-3">Speed up your searches.</li>
      <li id="privacy-policy-ul-6-li-4">Recognize when you return to the Website.</li>
    </ul>
    <h3 id="privacy-policy-subheading-4b">How We Use the Information We Collect</h3>
    <p id="privacy-policy-p21">
      We may use information that we collect about you or that you provide to us, including any personal information:
    </p>
    <ul id="privacy-policy-ul-7">
      <li id="privacy-policy-ul-7-li-1">To present our Website and its contents to you.</li>
      <li id="privacy-policy-ul-7-li-2">
        To provide you with information, products, or services that you request from us.
      </li>
      <li id="privacy-policy-ul-7-li-3">To fulfill any other purpose for which you provide it.</li>
      <li id="privacy-policy-ul-7-li-4">To provide you with notices about your account.</li>
      <li id="privacy-policy-ul-7-li-5">
        To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.
      </li>
      <li id="privacy-policy-ul-7-li-6">
        To notify you about changes to the Website or any products or services we offer through it.
      </li>
      <li id="privacy-policy-ul-7-li-7">In any other way we describe when you provide the information.</li>
      <li id="privacy-policy-ul-7-li-8">For any other purpose with your consent.</li>
      <li id="privacy-policy-ul-7-li-9">
        To conduct transactions, surveys, research, marketing, data analysis and enrichment.
      </li>
    </ul>
    <h3 id="privacy-policy-subheading-6">How We Disclose the Information We Collect</h3>
    <p id="privacy-policy-p22">
      We may disclose aggregated information about our users without restriction. All financial institutions need to
      share customer information for their everyday business purposes. In addition to such business purposes, we may
      disclose personal information and non-personal information that we collect or you provide as described in this
      Policy:
    </p>
    <ul id="privacy-policy-ul-9">
      <li id="privacy-policy-ul-9-li-1">
        To our subsidiaries and affiliates (i.e., companies related to us by common ownership or control).
      </li>
      <li id="privacy-policy-ul-9-li-2">
        To our contractors, service providers, and other third parties we use to support our business.
      </li>
      <li id="privacy-policy-ul-9-li-3">
        To other financial institutions that jointly offer, endorse or sponsor financial products or services with us.
      </li>
      <li id="privacy-policy-ul-9-li-4">
        To other individuals or entities, when we believe that disclosure is necessary to report suspicious activities,
        prevent physical harm, financial loss, or violations of our agreements and policies.
      </li>
      <li id="privacy-policy-ul-9-li-5">
        To regulators and other organizations or individuals who are legally entitled to receive such information.
      </li>
      <li id="privacy-policy-ul-9-li-6">
        To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution,
        or other sale or transfer of some or all of WaFd's assets, whether as a going concern or as part of bankruptcy,
        liquidation, or similar proceeding, in which personal information held by us about our Website users is among
        the assets transferred.
      </li>
      <li id="privacy-policy-ul-9-li-7">To fulfill the purpose for which you provided it.</li>
      <li id="privacy-policy-ul-9-li-8">For any other purpose disclosed by us when you provide the information.</li>
      <li id="privacy-policy-ul-9-li-9">With your consent.</li>
      <li id="privacy-policy-ul-9-li-10">
        To comply with any court order, law, or legal process, including to respond to any government or regulatory
        request.
      </li>
      <li id="privacy-policy-ul-9-li-11">To enforce or apply any other policies and notices linked on the Website.</li>
      <li id="privacy-policy-ul-9-li-12">
        If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of WaFd, our
        customers, or others. This includes exchanging information with other companies and organizations for the
        purposes of fraud protection and credit risk reduction. However, we do not sell personal information or
        sensitive personal information to third parties.
      </li>
    </ul>
    <h3 id="privacy-policy-subheading-7">Third-Party Sites and Services</h3>
    <p id="privacy-policy-p23">
      Our online services may contain links to third-party websites and other online services, which are not governed by
      this Policy. As such, we encourage you to learn about the privacy and security practices of those third parties.
      We are not responsible for the privacy or security of such websites and other online services, nor the online
      collection, use or sharing of personal or non-personal information by third parties.
    </p>
    <h3 id="privacy-policy-subheading-8">Mobile Security</h3>
    <p id="privacy-policy-p24">
      WaFd has implemented mobile security standards and practices to protect the privacy of your personal information.
      The WaFd apps; Zelle<sup>&reg;</sup>; and Mobile Deposit present "Blind Data." This means our mobile services do
      not present full names, Social Security Numbers, or other personal information. Account numbers are truncated, and
      Mobile Deposit does not store check images in persistent memory.
    </p>
    <h3 id="privacy-policy-subheading-9">E-Mail Privacy</h3>
    <p id="privacy-policy-p25">
      WaFd will never ask for personal information through unencrypted e-mail. E-mail is not a secure method for sending
      sensitive personal or financial information including:
    </p>
    <ul id="privacy-policy-ul-10">
      <li id="privacy-policy-ul-10-li-1">Social Security Numbers</li>
      <li id="privacy-policy-ul-10-li-2">Account Numbers</li>
      <li id="privacy-policy-ul-10-li-3">Loan Numbers</li>
      <li id="privacy-policy-ul-10-li-4">Driver License or State ID Numbers</li>
    </ul>
    <p id="privacy-policy-p26">
      If you need to send sensitive information to WaFd Bank, please contact or visit your local branch or office to
      learn more about our secure e-mail encryption portal.
    </p>
    <h3 id="privacy-policy-subheading-10">Online Authentication</h3>
    <p id="privacy-policy-p27">
      When you obtain one of our products or services online, or register for or use one of our online services, you may
      be required to perform an online authentication process. Some of these processes utilize third party information
      services to verify your identity. For example, you may be asked to confirm details about a previous financial
      transaction (e.g., the amount of your monthly payment to a third party). The information that you provide in
      response to such questions will be used for the sole purpose of verifying your identity, and will not affect your
      credit rating or credit file. Our online authentication processes may collect a unique device identifier, IP
      address, and related data associated with your mobile device, and/or use cookies, tags and JavaScript, to identify
      the computer or mobile device or browser that you are using to access our site. These processes are used to
      protect both you and us from fraud and unauthorized transactions.
    </p>
    <h3 id="privacy-policy-subheading-11">Accuracy of Your Information</h3>
    <p id="privacy-policy-p28">
      The accuracy of your account and other personal information is important. If you have a financial product or
      service with us and you find that any personal information we have or have reported to another party does not
      appear to be accurate, please contact us as follows:
    </p>
    <p id="privacy-policy-p29">
      WaFd Bank
      <br />
      Attn: Client Care Center
      <br />
      9929 Evergreen Way
      <br />
      Everett, WA 98204
    </p>
    <p id="privacy-policy-p30">Please include your account number, if available.</p>
    <h3 id="privacy-policy-subheading-12">Social Media</h3>
    <p id="privacy-policy-p31">
      WaFd Bank participates in social media platforms, such as Facebook<sup>&reg;</sup>, Twitter<sup>&reg;</sup> and
      LinkedIn<sup>&reg;</sup>, which enable online sharing and collaboration among their users. Any information or
      other content you post on these platforms, such as pictures, opinions or personal information, is subject to the
      platforms' terms of use and privacy and security policies; please refer to their policies to better understand
      your rights and obligations with regard to such content.
    </p>
    <h3 id="privacy-policy-subheading-13">Protecting Children's Online Privacy</h3>
    <p id="privacy-policy-p32">
      In accordance with the Children's Online Privacy Protection Act, we do not knowingly collect personal information
      from children (i.e., persons under age 13). We do not knowingly collect, store, use or share personal information
      about children, unless the information has been provided by an adult in connection with the opening of a deposit
      account for or with the child. We do not accept applications directly from children, and will not send unsolicited
      promotions to users who indicate they are less than 13 years of age except in connection with an account opened by
      an adult. This does not prevent parents from opening deposit accounts on behalf of their children. We will obtain
      parental consent prior to the creation of an account on behalf of a child.
    </p>
    <h3 id="privacy-policy-subheading-14">Your State Privacy Rights</h3>
    <p id="privacy-policy-p33">
      State consumer privacy laws may provide their residents with additional rights regarding our use of their personal
      information. If you are a resident of California, please review our{" "}
      <Link to="/privacy-center#ccpa-notice" id="ccpa-notice-link">
        www.wafdbank.com/privacy-center#ccpa-notice
      </Link>{" "}
      to learn more about the additional rights granted to you under the California Consumer Protection Act.
    </p>
    <h3 id="privacy-policy-subheading-15">Visitors Residing Outside the United States</h3>
    <p id="privacy-policy-p34">
      If you visit or use our online services, your personal and non-personal information may be collected, stored, used
      and processed in and transferred to, from and within the United States. In such instances, applicable U.S. federal
      and state laws shall govern. If you are a non-U.S. resident, such laws may not provide the same level of
      protection as the laws of the country of your residence.
    </p>
    <h3 id="privacy-policy-subheading-16">Changes to Our Privacy Policy</h3>
    <p id="privacy-policy-p35">
      We reserve the right to update this Policy from time to time to comply with applicable laws and to reflect changes
      in our online services. For this reason, we encourage you to periodically review this Policy. If we make changes
      to this Policy, we will revise the "Effective/Revised" date shown at the top of the Policy and post the revised
      Policy on the Website and through our other online services. The changes will become effective when we post the
      revised Policy. Your visit or use of any of our websites or other online services following such posting means
      that you accept the revised Policy.
    </p>
    <h3 id="privacy-policy-subheading-17">Contact Us</h3>
    <p id="privacy-policy-p36">
      If you have any questions or comments regarding our Privacy Policy, please contact us at:
    </p>
    <p id="privacy-policy-p37">
      WaFd Bank
      <br />
      Attn: Legal Department
      <br />
      425 Pike Street
      <br />
      Seattle, WA 98101
    </p>
  </>
);

export default PrivacyPolicyData;
