import React, { useEffect, useState } from "react";

import Icon from "../custom-widgets/icon";

import "./nav-buttons.bootstrap.scss";

const NavButtons = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    let hashLink = window.location.href.split("#")[1];
    const index = props.tabsData.findIndex((tab) => tab.id === hashLink);
    setActiveTab(index >= 0 ? index : 0);
  }, [props.tabsData]);

  return (
    <div id="button-nav-section">
      <section className={props.sectionClasses}>
        <div className="container">
          {props.title && <h1 className={props.titleClass}>{props.title}</h1>}
          {props.subTitle && <h4 className={props.subTitleClass}>{props.subTitle}</h4>}
          <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-3">
            {props.tabsData.map((tab, index) => (
              <div className="col-12 mb-2 mb-sm-3" key={index} id={`tab-${tab.contentId}`}>
                {tab.title && (
                  <div
                    className={`${
                      index === activeTab ? "button-nav-active-tab" : ""
                    } button-nav-tab card border-0 border-radius-12 h-100`}
                  >
                    <div className="card-body d-flex py-2 align-items-center align-items-sm-start">
                      <div className="mr-2">
                        {tab.iconSrc && <img className="button-nav-icon mt-sm-1" src={tab.iconSrc} alt="" />}
                      </div>
                      <div className="">
                        <div className="font-weight-bold text-success h4-font-size">
                          {tab.title}
                          <Icon class="d-inline-block d-sm-none ml-2 text-primary" name="arrow-down" />
                        </div>
                        <div className="text-gray-90 button-nav-tab-description text-sm d-none d-sm-block">
                          {tab.description}
                          <Icon name="arrow-down" class="ml-2 text-primary" />
                        </div>
                      </div>
                    </div>
                    <a
                      id={tab.id + "-link"}
                      href={`#${tab.contentId}`}
                      className={`${index === activeTab ? "pointer-events-none" : "stretched-link "}`}
                    >
                      <span className="sr-only">{tab.title}</span>
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-lg-9">
            {props.tabsData.map((tab, index) => (
              <div id={tab.contentId} key={index} className={index === activeTab ? "d-block" : "d-none"}>
                {tab.component}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default NavButtons;

NavButtons.defaultProps = {
  sectionClasses: "bg-success text-white",
  title: "",
  subTitle: "",
  titleClass: "text-white",
  subTitleClass: "text-white",
  tabsData: [
    {
      id: "string",
      title: "",
      component: null,
      description: "",
      iconSrc: "",
      contentId: ""
    },
    {
      id: "string",
      title: "",
      component: null,
      description: "",
      iconSrc: "",
      contentId: ""
    }
  ]
};
