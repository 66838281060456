import { useEffect, useState, useRef } from "react";
import { useLocation } from "@reach/router";

const usePrivacyCenterScrollToTop = () => {
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  const backToTopRef = useRef();
  let hash = useLocation().hash;

  // Scroll Functionality
  const scrollToTop = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  // Scroll Position
  useEffect(() => {
    backToTopRef.current.style.bottom = showBackToTopButton ? "0px" : "-60px";
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", function () {
        if (window.location.hash !== "#ccpa-request") {
          window.pageYOffset > 400 ? setShowBackToTopButton(true) : setShowBackToTopButton(false);
        } else {
          // If the hash is #ccpa-request, hide the Back to Top button
          setShowBackToTopButton(false);
        }
      });
    }
  }, [showBackToTopButton, hash]);

  return { scrollToTop, showBackToTopButton, backToTopRef };
};

export default usePrivacyCenterScrollToTop;
