import { useEffect, useState } from "react";

/**
 * This hook checks if alild is present in Url that means form is submitted
 *
 * @returns {boolean} Returns true or false if alild is present in Url that means form is submitted
 */
function useCheckMktoFormIsSubmitted() {
  const [mktoFormIsSubmitted, setMktoFormIsSubmitted] = useState(false);

  const [windowSearch, setWindowSearch] = useState(null);

  useEffect(() => {
    const handleLocationChange = () => {
      setWindowSearch(window.location.search);
    };

    // Add event listener only if window is available
    if (typeof window !== "undefined") {
      window.addEventListener("popstate", handleLocationChange);
      window.addEventListener("replaceState", handleLocationChange);

      // Set initial search value
      setWindowSearch(window.location.search);
    }

    // Cleanup function to remove event listener
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("popstate", handleLocationChange);
        window.removeEventListener("replaceState", handleLocationChange);
      }
    };
  }, []); // Empty dependency array ensures this effect runs only once when component mounts

  useEffect(() => {
    if (windowSearch != null) {
      // This effect will run every time the search parameter changes
      console.log("Search parameter changed:", windowSearch);

      // Add your logic here to handle the search parameter change
      // For example, you can parse the search parameter and perform actions accordingly
      let match;
      let urlParams = {};
      const replaceAddition = /\+/g; // Regex for replacing addition symbol with a space
      const search = /([^&=]+)=?([^&]*)/g;
      const decode = (s) => {
        return decodeURIComponent(s.replace(replaceAddition, " "));
      };

      const query = windowSearch.substring(1);

      while ((match = search.exec(query))) {
        urlParams[decode(match[1])] = decode(match[2]);
      }

      setMktoFormIsSubmitted("aliId" in urlParams);
    }
  }, [windowSearch]); // Add search to the dependency array

  /* useEffect(() => {
    if (typeof window === "undefined") {
      setMktoFormIsSubmitted(false);
      return;
    }
  }, [window?.location.search]); */

  return mktoFormIsSubmitted;
}

export default useCheckMktoFormIsSubmitted;
