import React from "react";
import Icon from "../custom-widgets/icon";

const CookiePolicyData = () => (
  <>
    <h1 id="cookie-policy-header">Cookie Policy</h1>
    <p>
      <a
        href="/documents/privacy/cookie-policy.pdf"
        className="text-decoration-none"
        id="cookie-policy-pdf"
        target="_blank"
      >
        <Icon name="file-pdf" class="mr-1" />
        Printer friendly version of Cookie Policy
      </a>
    </p>
    <h3 id="cookie-policy-subheading-1">Cookies, Web Beacons, and Other Tracking Technologies</h3>
    <h5 id="cookie-policy-subheading-1a">
      The technologies we use for the automatic data collection described above may include:
    </h5>
    <h3 id="cookie-policy-subheading-2">Cookies</h3>
    <p id="cookie-policy-p1">
      A cookie is a small file placed on your computer's hard drive. You may refuse to accept browser cookies by
      activating the appropriate setting on your browser. However, online banking at WaFd requires cookies to be
      accepted by the browser. The cookies allow us to track how users navigate through our Website. It also allows us
      to collect unique information about the user's device, such as an IP address. The device information stored within
      a cookie is used throughout the online banking user authentication process. In addition, the information within
      the cookie is used by back-end fraud prevention systems. WaFd does not collect or store personal information
      within cookies.
    </p>
    <p id="cookie-policy-p2">
      The Website does not currently respond to "Do Not Track" ("DNT") browser signals. WaFd will continue to monitor
      the progress of the DNT technology as it develops into an industry standard.
    </p>
    <h3 id="cookie-policy-subheading-3">Why Do We Use Cookies?</h3>
    <p id="cookie-policy-p3">
      We use cookies for several reasons. Cookies help us to provide you with a good experience when you browse our
      Website and also allow us to improve our site. Through cookies, we can:
    </p>
    <ul id="cookie-policy-ul-1">
      <li id="cookie-policy-ul-1-li-1">
        Understand your browsing behavior to develop and improve our products and services in response to your needs or
        wants.
      </li>
      <li id="cookie-policy-ul-1-li-2">Personalize our Website to you by remembering your preferences and settings.</li>
      <li id="cookie-policy-ul-1-li-3">
        Improve the security of our Website by supporting security features and aiding in the detection of fraudulent or
        illegal activities.
      </li>
      <li id="cookie-policy-ul-1-li-4">
        Measure and analyze traffic and browsing patterns to understand how our services are being used and how we can
        improve them.
      </li>
    </ul>
    <h3 id="cookie-policy-subheading-4">Types of Cookies We Use</h3>
    <ul id="cookie-policy-ul-2">
      <li id="cookie-policy-ul-2-li-1">
        <strong>Essential cookies:</strong> These are cookies that are required for the operation of our Website. They
        include, for example, cookies that enable you to log into secure areas of our Website.
      </li>
      <li id="cookie-policy-ul-2-li-2">
        <strong>Analytical/performance cookies:</strong> They allow us to recognize and count the number of visitors and
        to see how visitors move around our Website when they are using it. This helps us to improve the way our Website
        works, for example, by ensuring that users find what they are looking for easily.
      </li>
      <li id="cookie-policy-ul-2-li-3">
        <strong>Functionality cookies:</strong> These are used to recognize you when you return to our Website. This
        enables us to personalize our content for you and remember your preferences.
      </li>
      <li id="cookie-policy-ul-2-li-4">
        <strong>Targeting cookies:</strong> These cookies record your visit to our Website, the pages you have visited,
        and the links you have followed. We will use this information to make our Website, and the advertising displayed
        more relevant to your interests.
      </li>
    </ul>
    <h3 id="cookie-policy-subheading-5">Web Beacons</h3>
    <p id="cookie-policy-p4">
      Pages of our Website and our emails may contain small electronic files known as web beacons (also referred to as
      clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to count users who have visited those
      pages or opened an email and for other related website statistics (for example, recording the popularity of
      certain website content and verifying system and server integrity).
    </p>
    <h3 id="cookie-policy-subheading-6">Other Tracking Technologies</h3>
    <p id="cookie-policy-p5">
      Some content or applications, including advertisements, on the Website are served by third parties, including
      advertisers, ad networks and servers, content providers, and application providers. These third parties may use
      cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you
      when you use our Website. The information they collect may be associated with your personal information, or they
      may collect information, including personal information, about your online activities over time and across
      different websites and other online services. They may use this information to provide you with interest-based
      (behavioral) advertising or other targeted content.
    </p>
    <p id="cookie-policy-p6">
      We do not control these third parties' tracking technologies or how they may be used. If you have any questions
      about an advertisement or other targeted content, contact the responsible provider directly for information about
      how to opt out of receiving targeted advertising from many providers.
    </p>
    <h3 id="cookie-policy-subheading-7">Your Consent</h3>
    <p id="cookie-policy-p7">
      By using our Website, you consent to our use of cookies in accordance with this Cookie Policy. If you do not agree
      to our use of cookies, please ensure you set your browser settings accordingly, or do not use our Website.
    </p>
    <h3 id="cookie-policy-subheading-8">Changes to This Policy</h3>
    <p id="cookie-policy-p8">
      We may update this policy from time to time. Any changes we may make to our Cookie Policy in the future will be
      posted on this page.
    </p>
  </>
);

export default CookiePolicyData;
